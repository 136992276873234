import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, Divider, Logo, Stack, Typography } from "@tracktor/design-system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

interface ErrorFallbackProps {
  fullPage?: boolean;
}

const styles = {
  divider: {
    marginY: 4,
    width: 200,
  },
  link: {
    textDecoration: "none",
  },
};

const ErrorFallback = ({ fullPage }: ErrorFallbackProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const reload = () => {
    navigate(0);
  };

  return (
    <Stack sx={{ alignItems: "center", height: "100%", justifyContent: "center" }}>
      {fullPage && (
        <>
          <Logo variant="supplier" />
          <Box marginY={4} />
        </>
      )}
      <Typography variant="h2" textAlign="center">
        {t("oups")}
        <br />
        {t("anErrorOccurred")}
      </Typography>
      <Divider sx={styles.divider} />
      <Button variant="contained" onClick={reload} startIcon={<RefreshIcon />}>
        {t("reload")}
      </Button>
    </Stack>
  );
};

export default ErrorFallback;
