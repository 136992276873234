const RESOURCES_EN = {
  AIPR: "AIPR",
  BTP_PRO_CARD: "BTP Pro Card",
  CACES: "CACES",
  CE_CERTIFICATE: "CE Certificate",
  CE_CERTIFICATION: "CE Certification",
  DELIVERY: "Delivery",
  DRIVING_AUTHORIZATION: "Driving Authorization",
  DUE: "Due",
  ICON: "Icon",
  ID: "ID",
  IDENTITY: "Identity",
  INSURANCE: "Insurance",
  INVOICE: "Invoice",
  KBIS: "Kbis",
  LIFTING_PLAN: "Lifting Plan",
  LIFTING_SHEET: "Lifting Sheet",
  LOGO: "Logo",
  MACHINE_SPECIFICATIONS: "Machine Specifications",
  MAINTENANCE_LOG: "Maintenance Log",
  MAINTENANCE_LOG_EXCERPT: "Maintenance Log Excerpt",
  MEDICAL_CERTIFICATE: "Medical Certificate",
  OPERATOR_DOCUMENT: "Operator Document",
  OTHER: "Other",
  PGI: "PGI",
  PIC: "PIC",
  PURCHASE_ORDER: "Purchase Order",
  RIB: "RIB",
  SIGNED_RENTAL_REQUEST: "Signed Rental Request",
  SPECIFIC_TRAINING: "Specific Training",
  TECHNICAL_SHEET: "Technical Sheet",
  USED_MACHINE_CERTIFICATE: "Used Machine Certificate",
  USER_MANUAL: "User Manual",
  VGP: "VGP",
} as const;

export default RESOURCES_EN;
