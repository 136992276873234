const RESOURCES_FR = {
  AIPR: "AIPR",
  BTP_PRO_CARD: "Carte BTP Pro",
  CACES: "CACES",
  CE_CERTIFICATE: "Certificat CE",
  CE_CERTIFICATION: "Certification CE",
  DELIVERY: "Livraison",
  DRIVING_AUTHORIZATION: "Autorisation de conduite",
  DUE: "DUE",
  ICON: "Icône",
  ID: "ID",
  IDENTITY: "Identité",
  INSURANCE: "Assurance",
  INVOICE: "Facture",
  KBIS: "KBIS",
  LIFTING_PLAN: "Plan de levage",
  LIFTING_SHEET: "Fiche de levage",
  LOGO: "Logo",
  MACHINE_SPECIFICATIONS: "Spécifications machine",
  MAINTENANCE_LOG: "Carnet d'entretien",
  MAINTENANCE_LOG_EXCERPT: "Extrait du carnet d'entretien",
  MEDICAL_CERTIFICATE: "Certificat médical",
  OPERATOR_DOCUMENT: "Document opérateur",
  OTHER: "Autre",
  PGI: "PGI",
  PIC: "PIC",
  PURCHASE_ORDER: "Bon de commande",
  RIB: "RIB",
  SIGNED_RENTAL_REQUEST: "Demande de location signée",
  SPECIFIC_TRAINING: "Formation spécifique",
  TECHNICAL_SHEET: "Fiche technique",
  USED_MACHINE_CERTIFICATE: "Certificat de machine d'occasion",
  USER_MANUAL: "Manuel d'utilisation",
  VGP: "VGP",
} as const;

export default RESOURCES_FR;
