const ROUTES = {
  analytics: "/analytics",
  billings: "/billings",
  company: "/company",
  dashboard: "/dashboard",
  deals: "/deals",
  dealsId: (id: string) => `${ROUTES.deals}/id/${id}`,
  dealsTab: (tab?: string) => `${ROUTES.deals}/${tab}`,
  forgotPassword: "/forgot-password",
  help: "/help",
  info: "/info",
  login: "/login",
  payments: "/payments",
  profile: "/profile",
  queryError: "/query-error",
  requests: "/requests",
  requestsId: (id: string) => `${ROUTES.requests}/id/${id}`,
  requestsTab: (tab?: string) => `${ROUTES.requests}/${tab}`,
  resource: (id: string | number) => `${ROUTES.resources}/${id}`,
  resources: "/resources",
} as const;

export default ROUTES;
