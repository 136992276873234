import { useLocalStorage } from "@tracktor/react-utils";
import { useAuth } from "@tracktor/shared-module";
import { useCallback } from "react";
import { User as UserModel } from "@/api/saas/model";
import LOCAL_STORAGE_KEY from "@/constants/localStorageKey";
import decodeToken from "@/utils/decodeToken";

export interface User extends Partial<UserModel> {
  accessToken: string;
  tokenType?: string;
}

const useUser = () => {
  const [userLocal, setUserLocal, removeUserLocal] = useLocalStorage<null | User>(LOCAL_STORAGE_KEY.user);
  const { clearAuthenticationToken } = useAuth();
  const { scope, entity_id: entityId } = decodeToken(userLocal?.accessToken) || {};
  const isLogged = !!userLocal?.accessToken;

  const setUser = useCallback(
    (newUser?: User) => {
      if (!newUser) {
        return;
      }

      setUserLocal((prevState) => ({ ...prevState, ...newUser }));
    },
    [setUserLocal],
  );

  const signOut = useCallback(() => {
    clearAuthenticationToken();
    removeUserLocal();
  }, [clearAuthenticationToken, removeUserLocal]);

  return {
    ...userLocal,
    entityId,
    isLogged,
    permissions: scope,
    setUser,
    signOut,
  };
};

export default useUser;
